
import Component, { ComponentMethods } from "@/module/component/component"

class ErrorView extends ComponentMethods implements ComponentEntity {
    public code: string = "404"

    onLoad(param: obj) {
        this.code = param.t
    }
}

export default Component.build(new ErrorView)
